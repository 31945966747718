import React from "react"
import Layout from "../components/Layout"
import Head from "../components/Head"
import "../styles/global.css"

export default function NotFound() {
  return (
    <div className="pageContent">
      <Head />
      <Layout>
        <div className="startAfterNavbar">
          <h2 className="mt-5 mx-3">Sorry, this page does not exist.</h2>
        </div>
      </Layout>
    </div>
  )
}
